import React from "react";
import PropTypes from "prop-types";
import { WmsLayer, LayerSwitch, LayerGroup } from "react-openlayers";
import { translate } from "react-translate";

const MapLayers = ({ t }) => (
  <LayerSwitch tooltip={t("selectMap")}>
    <LayerGroup
      id="basemap_group"
      singleSelect
      disabledLayers={["sjokart", "enc", "Norge_i_bilder"]}
    >
      <WmsLayer
        id="graatone"
        key="graatone"
        name={t("baseMap")}
        layerName="norges_grunnkart_graatone"
        version="1.1.1"
        useBaat
        uri={[
          "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache",
          "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache",
          "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache"
        ]}
      />
      <WmsLayer
        id="sjokart"
        key="sjokart"
        name={t("seaMap")}
        layerName="sjokartraster"
        version="1.1.1"
        zIndex={-2}
        useBaat
        uri={[
          "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache",
          "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache",
          "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache"
        ]}
      />
      <WmsLayer
        id="enc"
        key="enc"
        name={t("enc")}
        layerName="cells"
        version="1.1.1"
        zIndex={-2}
        useBaat
        uri="https://kart.kystverket.no/proxies/xEncProxy.ashx?"
      />
      <WmsLayer
        id="Norge_i_bilder"
        key="Norge_i_bilder"
        name={t("norwayInPictures")}
        format="image/jpeg"
        version="1.3.0"
        layerName="0"
        zIndex={-2}
        useBaat
        uri={[
          "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_v2",
          "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_v2",
          "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_v2"
        ]}
      />
    </LayerGroup>
  </LayerSwitch>
);

MapLayers.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate("MapLayers")(MapLayers);
