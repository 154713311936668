import { Component } from "react";
import PropTypes from "prop-types";
import { ensureArticleModuleConfig } from "react-article-module";
import { ts } from "../translations/translations";

export const icons = {
  ladestrom: "/images/ikon_ladeanlegg.svg",
  lng: "/images/ikon_lng.svg",
  landstrom: "/images/ikon_hoyspent.svg",
  metanol: "/images/ikon_metanol.svg"
};

export const SourceIndexes = {
  LADESTROM: "ladestrom",
  LNG: "lng",
  LANDSTROM: "landstrom",
  METANOL: "metanol"
};

export function titlePrefix(dataIndex) {
  const n = ts("Source", dataIndex);

  if (n) return n + ": ";
  return "";
}

class SourceConfig extends Component {
  componentDidMount() {
    const { sources } = this.props;
    if (sources) {
      sources.forEach(source => {
        source.config.fillOpacity = 0.2;

        source.zIndex = 0;

        switch (source.dataIndex) {
          case SourceIndexes.LADESTROM:
            source.config.featureStyle = {
              image: {
                src: icons.ladestrom,
                scale: 0.5
              }
            };
            break;

          case SourceIndexes.LNG:
            source.config.featureStyle = {
              image: {
                src: icons.lng,
                scale: 0.5
              }
            };
            break;

          case SourceIndexes.LANDSTROM:
            source.config.featureStyle = {
              image: {
                src: icons.landstrom,
                scale: 0.5
              }
            };
            break;

          case SourceIndexes.LAVSPENT:
            source.config.featureStyle = {
              image: {
                src: icons.lavspent,
                scale: 0.5
              }
            };
            break;

          case SourceIndexes.METANOL:
            source.config.featureStyle = {
              image: {
                src: icons.metanol,
                scale: 0.5
              }
            };
            break;

          default:
            break;
        }
      });
    }
  }

  render() {
    return null;
  }

  static propTypes = {
    sources: PropTypes.array
  };
}

export default ensureArticleModuleConfig(SourceConfig);
